import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';

import MainContainer, { getWidthBySize } from './layouts/MainContainer';
import { H2 } from './common/H.style';

const HomeSubTitleBar = styled.div`
  background-color: #f0862e;
`;
const HomeSubTitleBarBody = styled(Flex)`
  margin: 0 auto;
  width: ${({ size }) => getWidthBySize(size)}px;
  height: 60px;
`;

const ContentAtHome = ({ title, size = 'sm', children }) => (
  <div>
    <HomeSubTitleBar>
      <HomeSubTitleBarBody center size={size}>
        <H2 size={size}>{title}</H2>
      </HomeSubTitleBarBody>
    </HomeSubTitleBar>
    <MainContainer size={size}>{children}</MainContainer>
  </div>
);

export default ContentAtHome;
