import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

function InfoText() {
  const [text, setText] = useState('');

  useEffect(() => {
    fetch('https://wait.hirahata-clinic.or.jp/display_text_utf8.txt')
      .then((response) => response.text())
      .then((data) => setText(data))
      .catch((error) => console.error(error));
  }, []);

  const Info = styled.div`
    display: block;
    width: 90%;
    margin: 0.5em auto;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    word-wrap: break-word;
    white-space: pre-wrap;
  `;

  return (
    <div>
      <Info>{text}</Info>
    </div>
  );
}

export default InfoText;
