import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import SideMenu from './SideMenu';

import { useTrackedState } from '../lib/state';

import { GlobalStyle } from './layouts/Layout.style';
import SEO from './Seo';

const MainLayout = ({ children, topTitle }) => {
  const { size } = useTrackedState();
  const [openSideMenu, setOpenSideMenu] = useState(false);
  return (
    <>
      <GlobalStyle />
      <SEO pageTitle={topTitle} />
      <SideMenu size={size} open={openSideMenu} onClose={() => setOpenSideMenu(false)} />
      <Header size={size} onOpenSideMenu={() => setOpenSideMenu(true)} />
      {children}
      <Footer size={size} />
    </>
  );
};

export default MainLayout;
